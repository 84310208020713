export class GameProcess {
    constructor(
        public id: number,
        public game_id: number,
        public created_at: Date | null,
        public locked_at: Date | null,
        public updated_at: Date | null,
        public coef_up: number | null,
        public coef_down: number | null,
        public type: GameProcessType,
        public price_last: number | null,
        public price_locked: number | null,
        public current_price: number | null,
        public players_in_game: number | null,
        public prize_pool: number | null,
        public in_game: boolean | null,
        public player_price_win: number | null,
        public player_price_bet: number | null,
        public player_winner: boolean | null,
        public players_in_game_down: number | null,
        public players_in_game_up: number | null,
    ) { }
}

export function mapToGameProcess(data: any) {
    const created_at = data.created_at == null ? null : new Date(data.created_at);
    const locked_at = data.locked_at == null ? null : new Date(data.locked_at);
    const updated_at = data.updated_at == null ? null : new Date(data.updated_at);

    return new GameProcess(
        data.id,
        data.game_id,
        created_at,
        locked_at,
        updated_at,
        data.coef_up,
        data.coef_down,
        data.type_id,
        data.price_last,
        data.price_locked != null ? parseFloat(data.price_locked) : null,
        null,
        data.players_in_game,
        data.prize_pool == null ? null : parseFloat(data.prize_pool),
        data.in_game,
        data.player_price_win,
        data.player_price_bet,
        data.player_winner,
        data.players_in_game_down,
        data.players_in_game_up
    )
}

export enum GameProcessType {
    MoneyCollection = 1, // сбор денег
    CurrentlyActive = 2,  // активна сейчас
    Completed = 3     // уже прошла 
}