import React, { useContext, useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import BottomMenu from './Components/BottomMenu';
import coinImage from "./Resources/images/coin.svg";
import GameContext from "./Context/GameContext";
import avatarImage from "./Resources/images/avatar.jpg";
import WebAppUser from "@twa-dev/sdk";
import Loader from "./Components/Loader";
import { useTranslation } from "react-i18next";
import ReloadPage from "./Components/ReloadPage";
import pixie_0 from "./Resources/images/pixie/0.png";
import pixie_1 from "./Resources/images/pixie/1.png";
import pixie_2 from "./Resources/images/pixie/2.png";
import pixie_3 from "./Resources/images/pixie/3.png";
import pixie_4 from "./Resources/images/pixie/4.png";
import pixie_5 from "./Resources/images/pixie/5.png";
import pixie_6 from "./Resources/images/pixie/6.png";
import pixie_7 from "./Resources/images/pixie/7.png";
import pixie_8 from "./Resources/images/pixie/8.png";
import pixie_9 from "./Resources/images/pixie/9.png";
import menu_referrals_dark from './Resources/images/menu/new/referrals_dark.svg';
import menu_referrals_light from './Resources/images/menu/new/referrals_light.svg';
import menu_referrals from './Resources/images/menu/new/referrals.svg';
import { UserProvider } from './Domain/User/userProvider';
import { levels_score } from './constants';
import WebApp from '@twa-dev/sdk';
import { useTheme } from './Context/Predict/themeContext';



export default function Layout() {
    const { score, coinsPerClick, coinsPerSecond, totalEarn, updateGame, userId } = useContext(GameContext);

    const [firstName, setFirstName] = useState<string | null>(null);
    const [lastName, setLastName] = useState<string | null>(null);
    const [username, setUsername] = useState<string | null>(null);

    const [loaded, setLoaded] = useState<boolean>(false);
    const [attentionReloadPage, setAttentionReloadPage] = useState<boolean>(false);

    const [levelValue, setLevelValue] = useState<number>(0);

    const [passiveProfitPopup, setPassiveProfitPopup] = useState<boolean>(false);
    const [passiveProfitValue, setPassiveProfitValue] = useState<number>(0);
    const [coinsNeedForNewLevel, setCoinsNeedForNewLevel] = useState<number>(0);

    const { t, i18n } = useTranslation();

    const navigate = useNavigate();
    const location = useLocation();

    const { clickerTheme } = useTheme();

    const pixieImages = [
        pixie_0,
        pixie_1,
        pixie_2,
        pixie_3,
        pixie_4,
        pixie_5,
        pixie_6,
        pixie_7,
        pixie_8,
        pixie_9,
    ];

    useEffect(() => {
        levels_score.forEach((level, i) => {
            if (levels_score[i][0] <= totalEarn && levels_score[i][1] >= totalEarn) {
                // setLevelPercents(totalEarn / (levels_score[i][1] / 100));
                setLevelValue(i);
                setCoinsNeedForNewLevel(levels_score[i][1] - totalEarn);
            }
        });
    }, [totalEarn]);

    useEffect(() => {
        if (!userId) return;

        authUser();

        if (location.pathname === '/') navigate(`/${userId}`);
    }, []);

    async function authUser() {
        let user_language = (WebAppUser.initDataUnsafe && WebAppUser.initDataUnsafe.user) ? WebAppUser.initDataUnsafe.user.language_code : "ru";

        const response = await UserProvider.authUser(userId, user_language!, WebAppUser.initDataUnsafe?.start_param,
            WebAppUser.initDataUnsafe.user?.first_name,
            WebAppUser.initDataUnsafe.user?.last_name,
            WebAppUser.initDataUnsafe.user?.username,
            WebAppUser.initDataUnsafe.user?.language_code
        )

        setFirstName(response.user.first_name);
        setLastName(response.user.last_name);
        setUsername(response.user.username);

        let user_score = parseFloat(response.user.balance);
        let energy = parseInt(response.userurrent_energy);
        let total_earn = parseInt(response.user.total_earn);

        let maxEnergy = parseInt(response.user.max_energy);
        let temp_coins_per_second = parseFloat(response.user.coins_per_second);
        let temp_coins_per_click = parseFloat(response.user.coins_per_click);

        // if(response.user.skin_id !== null){
        //     console.log("есть бусты");
        //     maxEnergy = maxEnergy + (maxEnergy / 100 * parseInt(response.user.energy_bar_boost));
        //     temp_coins_per_second = temp_coins_per_second + (temp_coins_per_second / 100 * parseInt(response.user.earning_boost));
        //     temp_coins_per_click = temp_coins_per_click + parseFloat(response.user.per_tap_boost);
        // }

        let passiveProfitValue = parseFloat(response.user.balance) - parseFloat(response.user.old_balance);
        if (passiveProfitValue !== 0) {
            setPassiveProfitPopup(true);
        }

        setPassiveProfitValue(passiveProfitValue);
        user_score = parseFloat(response.user.balance);
        total_earn = total_earn + (parseFloat(response.user.balance) - parseFloat(response.user.old_balance));
        energy = response.user.current_energy;
        const dao_balance = parseFloat(response.user.dao_balance);

        updateGame({
            score: user_score,
            coinsPerClick: temp_coins_per_click,
            coinsPerSecond: temp_coins_per_second,
            playerImprovements: JSON.parse(response.user.improvements_data),
            energy: energy,
            maxEnergy: maxEnergy,
            totalEarn: total_earn,
            coinImageId: parseInt(response.user.coin_image_id),
            skinImageId: parseInt(response.user.skin_image_id),
            coinId: parseInt(response.user.coin_id),
            skinId: parseInt(response.user.skin_id),
            coinShadowColor: response.user.coin_shadow_color,
            skinEarningBoost: response.user.earning_boost,
            skinPerTapBoost: response.user.per_tap_boost,
            energyBarBoost: response.user.energy_bar_boost,
            level: levelValue,
            family_id: response.user.family_id ? parseInt(response.user.family_id) : null,
            token: response.token,
            event_clicks_counter: response.user.clicks_counter,
            default_coins_per_second: parseFloat(response.default_coins_per_second),
            default_coins_per_tap: parseInt(response.default_coins_per_tap),
            default_max_energy: parseInt(response.default_max_energy),
            eventTimeEndSecond: parseInt(response.event_end_datetime),
            energyPerSecond: parseInt(response.user.energy_per_second),
            dao_balance: dao_balance
        });

        if (parseInt(response.user.coin_image_id) === 9) {
            // document.body.classList.add('golden-pixie-coin-event');
        }

        setLoaded(true);
    }

    useEffect(() => {
        const interval = setInterval(() => {
            updateGame(prev => ({
                ...prev,
                score: prev.score + prev.coinsPerSecond,
                energy: prev.energy >= prev.maxEnergy ? prev.energy = prev.maxEnergy : prev.energy + prev.energyPerSecond,
                totalEarn: prev.totalEarn + prev.coinsPerSecond,
                eventTimeEndSecond: prev.eventTimeEndSecond - 1
            }));
        }, 1000);

        return () => clearInterval(interval);
    }, [coinsPerSecond]);

    function parseBigNumber(number: number) {
        if (number >= 10000 && number < 1000000) {
            return `${Math.round(number / 1000)}k`
        } else if (number >= 1000000) {
            return `${(number / 1000000).toFixed(3)}kk`
        } else {
            return `${Math.round(number)}`
        }
    }

    function passiveProfitPopupClose() {
        setPassiveProfitPopup(false);
    }

    function nicknameFormat(first_name: string | null, last_name: string | null, username: string | null) {
        let nickname;
        if (first_name !== 'None' && first_name !== null) {
            nickname = first_name;
        }
        if (last_name !== 'None' && last_name !== null) {
            if (nickname !== "") {
                nickname = nickname + " " + last_name;
            } else {
                nickname = last_name;
            }
        }

        if (nickname === "") {
            nickname = username;

            if (nickname === "") {
                nickname = "Hidden username";
            }
        }

        return nickname;
    }

    if (attentionReloadPage) return <ReloadPage />;
    if (!loaded || score == null) return <Loader />

    return (
        <div className="app" >
            {
                passiveProfitPopup &&
                <div className="offline_profit_container" >
                    <div className="offline_profit_container-content" >
                        <span className="offline_profit_container-content-text" > {t('Passive income amounted to')} </span>
                        < span className="offline_profit_container-content-value" >
                            <img src={coinImage} alt="" />
                            {Math.round(passiveProfitValue).toLocaleString('en')}
                        </span>
                        < button className="offline_profit_container-content-button" onClick={passiveProfitPopupClose} > OK </button>
                    </div>
                    <div className="offline_profit_container-overlay"> </div>
                </div>
            }
            <div className="game-container_header" >
                <div className="game-container_header-leftSide" >
                    {/*<img src={avatarImage} alt="" />*/}
                    < img src={pixieImages[userId % 10]} alt="avatar" />
                    <span className="game-container_header-leftSide-name" >
                        {/*{firstName !== 'None' ? firstName : ''} {lastName !== 'None' ? lastName : ''} || username}*/}
                        {/*{firstName ? firstName + " " + (lastName === 'None' ? '' : lastName) : username || 'Hidden username'}*/}
                        {nicknameFormat(firstName, lastName, username)}
                    </span>
                </div>
                <div className="game-container_header-rightSide" onClick={() => navigate("/referrals")}>
                    <img src={clickerTheme == null ? menu_referrals : clickerTheme == 'dark' ? menu_referrals_dark : menu_referrals_light} alt="Referrals" />
                    <span>{t("Referrals")}</span>
                </div>
            </div>
            <div className="game-container" >
                <div className="game-container_stats" >
                    <div className="game-container_stats-item" >
                        <span className="game-container_stats-item-name" > {t('Earn per tap')} </span>
                        < span className={`game-container_stats-item-value ${score < 30_000_000 ? 'pulse' : ''}`} >
                            <img src={coinImage} alt="" />
                            {
                                score < 30_000_000
                                    ? <>+10k</>
                                    : <>+{coinsPerClick}</>
                            }
                        </span>
                    </div>
                    < div className="game-container_stats-item" >
                        <span className="game-container_stats-item-name" > {t('Coins to level up')} </span>
                        {/*<span className="game-container_stats-item-name">{t('clicks')}</span>*/}
                        <span className="game-container_stats-item-value" >
                            {
                                coinsNeedForNewLevel > 1000 ?
                                    (coinsNeedForNewLevel > 1000000 ?
                                        `${Math.round(coinsNeedForNewLevel / 1000000)}KK`
                                        :
                                        `${Math.round(coinsNeedForNewLevel / 1000)}K`
                                    )
                                    :
                                    Math.round(coinsNeedForNewLevel)
                            }
                        </span>
                        {/*<span className="game-container_stats-item-value">{event_clicks_counter}</span>*/}
                    </div>
                    <div className="game-container_stats-item" >
                        <span className="game-container_stats-item-name" > {t('Profit per hour')} </span>
                        < span className="game-container_stats-item-value" >
                            <img src={coinImage} alt="" />
                            {parseBigNumber(coinsPerSecond * 60 * 60)}
                        </span>
                    </div>
                </div>
                <span className={`score ${score < 30_000_000 ? 'pulse' : ''}`} style={{ fontSize: score > 9_999_999_999 ? "25px" : "30px" }}>
                    <img src={coinImage} alt="" />
                    {Math.round(score).toLocaleString('en')}
                </span>
            </div>
            < Outlet />
            <BottomMenu />
        </div>
    );
};