import { User } from "@telegram-apps/sdk-react";
import { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import GameContext from "../../../../Context/GameContext";
import { PredictLeadBoardInfo } from "../../../../Domain/Predict/leadboard/predictLeadBoardInfo";
import { PredictLeadBoardProvider } from "../../../../Domain/Predict/leadboard/leadbordProvider";
import PLoader from "../../../../Components/Predict/loader/Loader";
import { useTheme } from "../../../../Context/Predict/themeContext";
import menu_predict_dark from '../../../../Resources/images/menu/new/predict_dark.svg';
import menu_predict_light from '../../../../Resources/images/menu/new/predict_light.svg';
import menu_predict from '../../../../Resources/images/menu/new/predict.svg';
import style from './preidctLeadBoardStyle.module.scss'
import { skins_images } from "../../../../constants";
import default_skin from "../../../../Resources/images/human-free.png";
import { useNavigate } from "react-router-dom";
import PixieLogo from '../../../../Resources/Predict/images/PixieLogo.png';
import СoinIcon from '../../../../Resources/Predict/images/coin.svg';
import СoinBrownIcon from '../../../../Resources/Predict/icons/coin_brown.svg';


export default function PredictLeaderBoard() {
    const [predictLeadBoardInfos, setPredictLeadBoardInfos] = useState<PredictLeadBoardInfo[]>([]);
    const [currentUserBets, setCurrentUserBets] = useState<number | null>(null);
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [userInLeaderBoard, setUserInLeaderBoard] = useState<boolean>(false);

    const { userId, token, skinId, score, dao_balance } = useContext(GameContext);
    const predictBalance = useMemo(() => parseFloat((score / 1_000_000).toFixed(2)), [score])

    const { t } = useTranslation();
    const { clickerTheme } = useTheme();

    const navigate = useNavigate();

    useEffect(() => {
        loadLeadBoard();
    }, []);

    async function loadLeadBoard() {
        const { leadBoardInfos, current_user_bets } = await PredictLeadBoardProvider.get(token!);
        setPredictLeadBoardInfos(leadBoardInfos);
        setCurrentUserBets(current_user_bets);

        const isUserInLeaderBoard = leadBoardInfos.some(item => item.telegram_user_id === userId);
        setUserInLeaderBoard(isUserInLeaderBoard);

        setIsLoaded(true);
    }

    function nicknameFormat(first_name: string | null, last_name: string | null) {
        let nickname = "";

        const firstNameValid = first_name !== 'None' && first_name !== null;
        const lastNameValid = last_name !== 'None' && last_name !== null;

        if (firstNameValid) {
            nickname = first_name;
        }

        if (lastNameValid) {
            if (nickname !== "") {
                nickname = nickname + " " + last_name;
            } else {
                nickname = last_name;
            }
        }

        if (nickname === "") {
            if (nickname === "") {
                nickname = "Hidden username";
            }
        }

        return nickname;
    }

    if (!isLoaded) return <PLoader />;

    return (
        <div className={style.mainPageContainer}>
            <div className={style.header}>
                <div className={style.header_left}>
                    <div className={style.header_left_logo} onClick={() => navigate("/predict/predictions")}>
                        <img src={PixieLogo} alt="PixieLogo" />
                    </div>

                </div>
                <div className={style.header_balance} onClick={() => navigate("/predict/profile/history")}>
                    <div>
                        <img src={СoinIcon} alt="СoinIcon" />
                        <div className={style.header_balance_title}>
                            {predictBalance.toLocaleString("en")}
                        </div>
                    </div>
                    <div>
                        <img src={СoinBrownIcon} alt="СoinIcon" />
                        <span className={style.header_balance_title}>{(+dao_balance.toFixed(2)).toLocaleString("en")}</span>
                    </div>
                </div>
            </div>
            <div className={style.leaderboardContainer}>
                <img src={clickerTheme == null ? menu_predict : clickerTheme == 'dark' ? menu_predict_dark : menu_predict_light} alt="" className="leaderboard-header-image" />
                <h3 className={style.leaderboardContainerName}>{t('Leaderboard by number of bets for today')}</h3>
                <div className={style.leaderboardList}>
                    {
                        predictLeadBoardInfos.map((leaderBoard, i) => (
                            <div key={i} className={`${style.leaderboardListItem} ${leaderBoard.telegram_user_id === userId ? style.current : ''}`}>
                                <div className={style.leaderboardListItemAvatarContainer}>
                                    <img src={leaderBoard.current_skin_id ? skins_images[leaderBoard.current_skin_id - 1] : default_skin} alt="avatar" className={style.leaderboardListItemAvatar} />
                                </div>
                                <div className={style.leaderboardListItemInfo}>
                                    <span className={style.leaderboardListItemInfoName}>{nicknameFormat(leaderBoard.first_name, leaderBoard.last_name)}</span>
                                    <span className={style.leaderboardListItemInfoBalance}>
                                        <img src={clickerTheme == null ? menu_predict : clickerTheme == 'dark' ? menu_predict_dark : menu_predict_light} alt="coin" className={style.leaderboardListItemInfoBalanceCoin} />
                                        {Math.round(leaderBoard.count).toLocaleString('en')}
                                    </span>
                                </div>
                                <div className={style.leaderboardListItemRating}>
                                    {i + 1}
                                </div>
                            </div>
                        ))
                    }
                    {
                        !userInLeaderBoard &&
                        <div className={`${style.leaderboardListItem} ${style.fixed} ${style.current}`}>
                            <div className={style.leaderboardListItemAvatarContainer}>
                                <img src={skinId ? skins_images[skinId - 1] : default_skin} alt="avatar" className={style.leaderboardListItemAvatar} />
                            </div>
                            <div className={style.leaderboardListItemInfo}>
                                <span className={style.leaderboardListItemInfoName}>You</span>
                                <span className={style.leaderboardListItemInfoBalance}>
                                    <img src={clickerTheme == null ? menu_predict : clickerTheme == 'dark' ? menu_predict_dark : menu_predict_light} alt="coin" className="leaderboard-list_item-info-balance-coin" />
                                    {Math.round(currentUserBets ?? 0).toLocaleString('en')}
                                </span>
                            </div>
                            <div className={style.leaderboardListItemRating}>
                                +50
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}
