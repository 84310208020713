export class PredictLeadBoardInfo {
    constructor(
        public telegram_user_id: number,
        public first_name: string | null,
        public last_name: string | null,
        public count: number,
        public current_skin_id: number | null
    ) { }
}

export function maptoPredictLeadBoardInfo(data: any) {
    return new PredictLeadBoardInfo(
        data.telegram_user_id,
        data.first_name,
        data.last_name,
        data.count,
        data.current_skin_id
    )
}