import { useContext, useEffect, useMemo, useState } from 'react';
import { Toaster } from "react-hot-toast";
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { DownButton } from '../../../Components/Predict/actionButton/downButton';
import { UpButton } from '../../../Components/Predict/actionButton/upButton';
import PLoader from '../../../Components/Predict/loader/Loader';
import GameContext from '../../../Context/GameContext';
import { useTheme } from '../../../Context/Predict/themeContext';
import { Category } from '../../../Domain/Category/category';
import { CatergoryProvider } from '../../../Domain/Category/categoryProdider';
import { Game } from '../../../Domain/Predict/game';
import { PredictProvider } from '../../../Domain/Predict/predictProdiver';
import LinkIcon from '../../../Resources/Predict/icons/link.svg';
import LinkDarkIcon from '../../../Resources/Predict/icons/link_dark.svg';
import PixieLogo from '../../../Resources/Predict/images/PixieLogo.png';
import СoinIcon from '../../../Resources/Predict/images/coin.svg';
import СoinBrownIcon from '../../../Resources/Predict/icons/coin_brown.svg';
import style from './predictionsStyle.module.scss';
import PlusLogo from '../../../Resources/Predict/icons/plus_2.svg'
import menu_predict_dark from '../../../Resources/images/menu/new/predict_dark.svg';
import menu_predict_light from '../../../Resources/images/menu/new/predict_light.svg';
import FireIcon from '../../../Resources/Predict/icons/fire.svg';
import WalletConnectComponent from '../walletConnectComponent/walletConnectComponent';
import { TonConnectButton, useTonConnectUI } from '@tonconnect/ui-react';

export default function PredictionsPage() {
    const { token, userId, score, dao_balance } = useContext(GameContext);
    const predictBalance = useMemo(() => parseFloat((score / 1_000_000).toFixed(2)), [score])
    const { theme } = useTheme();

    const [games, setGames] = useState<Game[]>([]);
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [gamesIsLoaded, setGamesIsLoaded] = useState<boolean>(false);

    const [selectedCategoryId, setSelectedCategoryId] = useState<number | null>(-1);
    const [categories, setCategories] = useState<Category[]>([]);

    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    useEffect(() => {
        loadCaregories();
    }, [])

    useEffect(() => {
        loadGames();
    }, [selectedCategoryId])


    async function loadCaregories() {
        setIsLoaded(false);

        const categories = await CatergoryProvider.get(token!);
        const categoriesWithYourSelf = [new Category(-1, "All", "All", "All"), ...categories];

        setCategories(categoriesWithYourSelf);

        setIsLoaded(true);
    }

    async function loadGames() {
        if (selectedCategoryId == null) return;

        setGamesIsLoaded(false);

        const games = await PredictProvider.getGames(selectedCategoryId, token!);
        setGames(games);

        setGamesIsLoaded(true);
    }

    function navigateToGameProcess(game: Game) {
        if ([691].includes(game.id)) return;

        navigate(`/predict/predictions/${game.id}`)
    }

    function translatedName(caregory: Category) {
        if (i18n.language === 'ru') {
            return caregory.name_ru;
        } else if (i18n.language === 'uk') {
            return caregory.name_uk;
        } else {
            return caregory.name_en;
        }
    }

    function formatName(name: string | null) {
        if (name == null) return;

        const a = name.split(' ');
        return a[0];
    }

    function getPredictDescription(id: number) {
        switch (+id!) {
            case 1: return "Short 1-minute long BTC price prediction games with only two outcomes: higher or lower";
            case 3: return "Short 5-minute long ETH price prediction games with only two outcomes: higher or lower";
            case 56: return "Short 10-minute long TON price prediction games with only two outcomes: higher or lower";
            case 59: return "Short 10-minute long DOGS price prediction games with only two outcomes: higher or lower";
            case 60: return "Short 10-minute long NOT price prediction games with only two outcomes: higher or lower";
            case 61: return "Short 10-minute long HAMSTER price prediction games with only two outcomes: higher or lower";
            case 69: return "Short 1-minute long BTC price prediction games with only two outcomes: higher or lower";
        }
    }

    if (!isLoaded) return <PLoader />

    return (
        <div className={style.mainPageContainer}>
            <div className={style.header}>
                <div className={style.header_left}>
                    <div className={style.header_left_logo} onClick={() => navigate("/predict/predictions")}>
                        <img src={PixieLogo} alt="PixieLogo" />
                    </div>
                    {/* <div className={style.header_left_search}>
                        <img src={SearchIcon} alt="SearchIcon" />
                        <input type='text' placeholder='Search' />
                    </div> */}
                </div>
                {/* <div className={style.header_authButton} onClick={() => navigate("/predict/predictions/create")}>
                    <ButtonTelegramAuth />
                </div> */}
                <div className={style.header_right} >
                    <div className={style.header_createButton} onClick={() => navigate("/predict/predictions/create")}>
                        <img src={PlusLogo} alt="PlusLogo" />
                        <img src={theme == 'dark' ? menu_predict_dark : menu_predict_light} alt="Predict" />
                    </div>
                    <div className={style.header_balance} onClick={() => navigate("/predict/profile/history")}>
                        <div>
                            <img src={СoinIcon} alt="СoinIcon" />
                            <div className={style.header_balance_title}>
                                {predictBalance.toLocaleString("en")}
                            </div>
                        </div>
                        <div>
                            <img src={СoinBrownIcon} alt="СoinIcon" />
                            <span className={style.header_balance_title}>{(+dao_balance.toFixed(2)).toLocaleString("en")}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div>
                {
                    [939941303, 875591451, 215159309, 463600889].includes(userId) &&
                    <>
                        <TonConnectButton style={{ float: "right" }} />
                    </>
                }
            </div>

            <div className={style.category_filters}>
                {
                    categories.map(category =>
                        <div key={category.id}
                            onClick={() => setSelectedCategoryId(category.id)}
                            className={`${style.category_filters_category} ${selectedCategoryId == category.id ? style.active : ''}`}
                        >
                            {translatedName(category)}
                            {category.id == 5 && <img src={FireIcon} alt="FireIcon" />}
                        </div>
                    )
                }
            </div>

            <div className={style.gameListContainer}>
                {
                    gamesIsLoaded
                        ? games.map(game =>
                            <div key={game.id} className={style.gameListContainer_gameCard} onClick={() => navigateToGameProcess(game)}>
                                {[691].includes(game.id) && <div className={style.comingSoonContainer}> <span>Coming soon...</span> </div>}
                                <div className={style.gameListContainer_gameCard_info}>
                                    <div className={style.gameListContainer_gameCard_info_left}>
                                        <img src={game.image ? `${process.env.REACT_APP_FILE_STORAGE_URL}/${game.image}` : ""} alt="" />
                                    </div>
                                    <div className={style.gameListContainer_gameCard_info_right}>
                                        <div className={style.gameListContainer_gameCard_info_right_hedaer}>
                                            <span>
                                                Created by {game.creator_telegram_id == -1
                                                    ? <span >System</span>
                                                    : (game.last_name != null || game.first_name != null)
                                                        ? <span >
                                                            {game.first_name != null && `${game.first_name} `}

                                                            {game.last_name != null && game.last_name}
                                                        </span>
                                                        : <span>System</span>
                                                }
                                            </span>
                                            <div>
                                                <a href={`https://t.me/share/url?url=https://t.me/pixie_project_bot?start=predict${game.id}_${userId}&text=${game.description}`}>
                                                    <img src={theme == "dark" ? LinkDarkIcon : LinkIcon} alt="LinkIcon" />
                                                </a>
                                                {/* <img onClick={e => { e.stopPropagation() }} src={theme == "dark" ? CommentDarkIcon : CommentLightIcon} alt="CommentDarkIcon" /> */}

                                                {/* <img onClick={e => { e.stopPropagation() }} src={theme == "dark" ? OutlineStarDarkIcon : OutlineStarIcon} alt="OutlineStarIcon" /> */}
                                            </div>
                                        </div>
                                        <div className={style.gameListContainer_gameCard_info_right_gameName}>
                                            {game.name}
                                        </div>
                                        {
                                            [1, 3, 56, 59, 60, 61, 69].includes(game.id) &&
                                            <div className={style.gameListContainer_gameCard_info_right_gameDescription}>
                                                {getPredictDescription(game.id)}
                                            </div>
                                        }

                                        {/* <div className={style.gameListContainer_gameCard_info_right_statistics}>
                                    <div>
                                        <img src={theme == 'dark' ? CalendarDarkIcon : CalendarIcon} alt="CalendarIcon" />
                                        <span>{game.created_at.toLocaleDateString()}</span>
                                    </div>
                                </div> */}
                                    </div>
                                </div>

                                {
                                    [1, 3, 56, 59, 60, 61, 69].includes(game.id) &&
                                    <div className={style.gameListContainer_gameCard_action}>
                                        <DownButton
                                            title={formatName(game.button_name_down) ?? ""}
                                            subTitle={game.coef_down == null ? null : (+(+game.coef_down.toFixed(2))).toLocaleString('en') + "x"}
                                            isHot={selectedCategoryId == 5 && game.coef_down != null && game.coef_down > (game.coef_up ?? 0)}
                                        />
                                        <UpButton
                                            title={formatName(game.button_name_up) ?? ""}
                                            subTitle={game.coef_up == null ? null : (+(+game.coef_up.toFixed(2))).toLocaleString('en') + "x"}
                                            isHot={selectedCategoryId == 5 && game.coef_up != null && game.coef_up > (game.coef_down ?? 0)}
                                        />
                                    </div>
                                }

                                {
                                    ![1, 3, 56, 59, 60, 61, 69].includes(game.id) &&
                                    <div className={`${style.gameListContainer_gameCard_action} ${style.coef}`}>
                                        <div className={style.gameListContainer_gameCard_action_coef}>
                                            <div> {game.button_name_up}</div>
                                            <div>{(game.percents_up ?? 0).toFixed(1)}%</div>
                                            {(game.coef_up ?? 0) < (game.coef_down ?? 0)
                                                ? <DownButton
                                                    title={game.coef_up == null ? "0x" : (+(+game.coef_up.toFixed(2))).toLocaleString('en') + "x"}
                                                    withoutIcon
                                                    isHot={selectedCategoryId == 5 && game.coef_up != null && game.coef_up > (game.coef_down ?? 0)}
                                                />
                                                : <UpButton
                                                    title={game.coef_up == null ? "0x" : (+(+game.coef_up.toFixed(2))).toLocaleString('en') + "x"}
                                                    withoutIcon
                                                    isHot={selectedCategoryId == 5 && game.coef_up != null && game.coef_up > (game.coef_down ?? 0)}
                                                />
                                            }
                                        </div>
                                        <div className={style.gameListContainer_gameCard_action_coef}>
                                            <div>{game.button_name_down}</div>
                                            <div>{(game.percents_down ?? 0).toFixed(1)}%</div>
                                            {(game.coef_down ?? 0) < (game.coef_up ?? 0)
                                                ? <DownButton
                                                    title={game.coef_down == null ? "0x" : (+(+game.coef_down.toFixed(2))).toLocaleString('en') + "x"}
                                                    withoutIcon
                                                    isHot={selectedCategoryId == 5 && game.coef_down != null && game.coef_down > (game.coef_up ?? 0)}
                                                />
                                                : <UpButton
                                                    title={game.coef_down == null ? "0x" : (+(+game.coef_down.toFixed(2))).toLocaleString('en') + "x"}
                                                    withoutIcon
                                                    isHot={selectedCategoryId == 5 && game.coef_down != null && game.coef_down > (game.coef_up ?? 0)}
                                                />
                                            }
                                        </div>
                                    </div>
                                }

                            </div>
                        )
                        : <PLoader />
                }
            </div>
            <div><Toaster /></div>
        </div>
    )
}
