import { maptoPredictLeadBoardInfo, PredictLeadBoardInfo } from "./predictLeadBoardInfo";

export class PredictLeadBoardProvider {
    public static async get(token: string): Promise<{ leadBoardInfos: PredictLeadBoardInfo[], current_user_bets: number | null }> {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/predicts/v1/leaderboard/list`, {
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'x-api-key': token
            },
        })
            .then(response => response.json())
            .then(response => response)

        return { leadBoardInfos: (response.leaderboard as any[]).map(maptoPredictLeadBoardInfo), current_user_bets: response.current_user_bets };
    }
}