import { useNavigate, useParams } from 'react-router-dom'
import style from './predictionEditPageStyle.module.scss'
import PixieLogo from '../../../../Resources/Predict/images/PixieLogo.png'
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { RoundedSelect } from '../../../../Components/Predict/inputs/roundedSelect/roundedSelect';
import { RoundedTextInput } from '../../../../Components/Predict/inputs/roundedTextInput/roundedTextInput';
import ImagesIcon from '../../../../Resources/Predict/icons/images.svg'
import ImagesDarkIcon from '../../../../Resources/Predict/icons/images_dark.svg'
import { useTheme } from '../../../../Context/Predict/themeContext';
import DatePicker from '../../../../Components/Predict/inputs/datePicker/datePicker';
import { Category } from '../../../../Domain/Category/category';
import { CatergoryProvider } from '../../../../Domain/Category/categoryProdider';
import GameContext from '../../../../Context/GameContext';
import { useTranslation } from 'react-i18next';
import СoinIcon from '../../../../Resources/Predict/images/coin.svg'
import { PredictProvider } from '../../../../Domain/Predict/predictProdiver';
import PLoader from '../../../../Components/Predict/loader/Loader';
import toast, { Toaster } from 'react-hot-toast';
import MiniLoader from '../../../../Components/Predict/loader/MiniLoader';
import Modal from '../../../../Components/Predict/modal/modal';
import { Card } from '../../../../Components/Predict/card/card';

export class PredictBlank {
    constructor(
        public category_id: number | null,
        public name: string | null,
        public description: string | null,
        public end_at: Date | null,
        public start_at: Date | null,
        public image: File | null,
        public button_name_up: string | null,
        public button_name_down: string | null
    ) { }
}

export function PredictionEditPage() {
    const { theme } = useTheme();
    const { t, i18n } = useTranslation();
    const { token, score, updateGame } = useContext(GameContext);
    const predictBalance = useMemo(() => Math.round(score / 1_000_000), [score])
    const navigate = useNavigate();

    const [predict, setPredict] = useState<PredictBlank>(new PredictBlank(null, null, null, null, new Date(), null, "Up", "Down"));
    const [categories, setCategories] = useState<Category[]>([]);
    const [validateErrors, setValidateErrors] = useState<string[]>([]);

    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [isCreatingLoading, setIsCreatingLoading] = useState<boolean>(false);

    const [isShowResultPopup, setIsShowResultPopup] = useState<boolean>(false);

    useEffect(() => {
        loadCategories()
    }, [])

    async function loadCategories() {
        setIsLoaded(false);

        const categories = await CatergoryProvider.get(token!);
        setCategories([...categories, new Category(-2, "Other", "Другое", "Інший")]);

        setIsLoaded(true);
    }

    function translatedName(caregory: Category | null) {
        if (caregory == null) return "";

        if (i18n.language === 'ru') {
            return caregory.name_ru;
        } else if (i18n.language === 'uk') {
            return caregory.name_uk;
        } else {
            return caregory.name_en;
        }
    }

    function selectFile() {
        let file: File;

        const input = document.createElement('input');
        input.type = "file";
        input.accept = ".png, .jpg, .jpeg";

        input.addEventListener('change', async (event: Event) => {
            const target = event.target as HTMLInputElement;

            if (target.files && target.files[0]) {
                file = target.files[0];
                setPredict(prev => ({ ...prev, image: file }))
            }
        });

        input.click();
    }

    async function createPredict() {
        if (isCreatingLoading) return;
        if (predictBalance < 500) return;
        if (!validatePredict()) return;

        setIsCreatingLoading(true);
        const response = await PredictProvider.create(predict, token!);
        if (response.message == "ok") {
            setIsCreatingLoading(false);
            setIsShowResultPopup(true);
            updateGame({ score: score - 1_000 * 1_000_000 })

        } else {
            setIsCreatingLoading(false);
            toast.error(response.message)
        }
    }

    function validatePredict() {
        const errorKeys: string[] = [];

        if (predict.category_id == null) errorKeys.push("category_id");
        if (predict.name == null || predict.name == "") errorKeys.push("name");
        if (predict.description == null || predict.description == "") errorKeys.push("description");
        if (predict.end_at == null) errorKeys.push("end_at");
        if (predict.start_at == null) errorKeys.push("start_at");
        if (predict.image == null) errorKeys.push("image");
        if (predict.image && predict.image.size > 2097152) {
            toast.error("File size should not exceed 2MB")
            errorKeys.push("image");
        }
        if (predict.button_name_up == null || predict.button_name_up == "") errorKeys.push("button_name_up");
        if (predict.button_name_down == null || predict.button_name_down == "") errorKeys.push("button_name_down");

        setValidateErrors(errorKeys);

        setTimeout(() => {
            setValidateErrors([]);
        }, 3000)

        if (errorKeys.length > 0) return false;

        return true;
    }

    if (!isLoaded) return <PLoader />

    return (
        <div className={style.mainPageContainer}>
            <div className={style.header}>
                <div className={style.header_logo} onClick={() => navigate("/predict/predictions")}>
                    <img src={PixieLogo} alt="PixieLogo" />
                </div>
            </div>
            <div className={style.createContainer}>
                <div className={style.createContainer_item}>
                    <div className={style.createContainer_item_title}>
                        Category
                    </div>
                    <div className={style.createContainer_item_input}>
                        <RoundedSelect<Category | null>
                            options={categories.map(caregory => ({ value: caregory }))}
                            getOptionLabel={translatedName}
                            getOptionValue={option => option?.id ?? ""}
                            onChange={category => setPredict(prev => ({ ...prev, category_id: category?.id ?? null }))}
                            value={categories.find(c => c.id == predict.category_id) ?? null}
                            placeholder='Category'
                            isError={validateErrors.some(element => element == "category_id")}
                        />
                    </div>
                </div>
                <div className={style.createContainer_item}>
                    <div className={style.createContainer_item_title}>
                        Describe your prediction in one sentence
                    </div>
                    <div className={style.createContainer_item_input}>
                        <RoundedTextInput
                            value={predict.name}
                            onChange={(name) => setPredict(prev => ({ ...prev, name }))}
                            placeholder={"Describe your prediction in one sentence"}
                            multiline
                            minRows={3}
                            isError={validateErrors.some(element => element == "name")}
                        />
                    </div>
                </div>
                <div className={style.createContainer_item}>
                    <div className={style.createContainer_item_title}>
                        Add full description of the event
                    </div>
                    <div className={style.createContainer_item_input}>
                        <RoundedTextInput
                            value={predict.description}
                            onChange={(description) => setPredict(prev => ({ ...prev, description }))}
                            placeholder={"Add full description of the event"}
                            multiline
                            minRows={3}
                            isError={validateErrors.some(element => element == "description")}
                        />
                    </div>
                </div>
                <div className={style.createContainer_item}>
                    <div className={style.createContainer_item_title}>
                        Add prediction start date
                    </div>
                    <div className={style.createContainer_item_input}>
                        <DatePicker
                            value={predict.start_at}
                            onChange={(date) => setPredict(prev => ({ ...prev, start_at: date }))}
                            placeholder='Add prediction validation date'
                            isError={validateErrors.some(element => element == "start_at")}
                        />
                    </div>
                </div>
                <div className={style.createContainer_item}>
                    <div className={style.createContainer_item_title}>
                        Add prediction validation date
                    </div>
                    <div className={style.createContainer_item_input}>
                        <DatePicker
                            value={predict.end_at}
                            onChange={(date) => setPredict(prev => ({ ...prev, end_at: date }))}
                            placeholder='Add prediction validation date'
                            isError={validateErrors.some(element => element == "end_at")}
                        />
                    </div>
                </div>
                <div className={style.createContainer_item}>
                    <div className={style.createContainer_item_title}>
                        Add photo
                    </div>
                    <div className={`${style.createContainer_item_photo_input} ${validateErrors.some(e => e == 'image') ? style.error : ""}`} onClick={selectFile}>
                        <img src={theme == 'light' ? ImagesIcon : ImagesDarkIcon} alt="ImagesIcon" />
                        {
                            predict.image == null
                                ? <span>Choose from gallery</span>
                                : <span>{predict.image.name}</span>
                        }
                    </div>
                </div>

                <div className={style.createContainer_item}>
                    <div className={style.createContainer_item_title}>
                        Vote
                    </div>
                    <div className={style.createContainer_item_buttons}>
                        <div className={`${style.button_container} ${style.green} ${validateErrors.some(e => e == 'button_name_up') ? style.error : ""}`}>
                            <input
                                value={predict.button_name_up ?? ""}
                                onChange={(e) => setPredict(prev => ({ ...prev, button_name_up: e.target.value }))}
                                className={`${style.title} ${style.green}`}
                            />
                            <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.63397 1.5C6.01887 0.833333 6.98113 0.833334 7.36603 1.5L10.8301 7.5C11.215 8.16667 10.7339 9 9.9641 9H3.0359C2.2661 9 1.78497 8.16667 2.16987 7.5L5.63397 1.5Z" fill={theme === 'dark' ? '#25b35e' : "#212121"} />
                            </svg>
                        </div>

                        <div className={`${style.button_container} ${style.red} ${validateErrors.some(e => e == 'button_name_down') ? style.error : ""}`}>
                            <input
                                value={predict.button_name_down ?? ""}
                                className={`${style.title} ${style.red}`}
                                onChange={(e) => setPredict(prev => ({ ...prev, button_name_down: e.target.value }))}
                            />
                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.13397 10.5C5.51887 11.1667 6.48113 11.1667 6.86603 10.5L10.3301 4.5C10.715 3.83333 10.2339 3 9.4641 3H2.5359C1.7661 3 1.28497 3.83333 1.66987 4.5L5.13397 10.5Z" fill="#F54570" />
                            </svg>
                        </div>
                    </div>
                </div>
            </div>

            <div className={style.notification} >
                The prediction creation price is <img src={СoinIcon} alt="СoinIcon" /> 1 000. By hitting confirm you agree to the conditions of the prediction creation
            </div>


            <div className={style.confirmButton} onClick={() => createPredict()} style={{ opacity: predictBalance < 500 ? 0.5 : 1 }}>
                {
                    isCreatingLoading
                        ? <MiniLoader color='light' />
                        : "Confirm"
                }
            </div>

            <div><Toaster /></div>

            <Modal isOpen={isShowResultPopup} onClose={() => setIsShowResultPopup(false)}>
                <Card className={style.resultModalContainer}>
                    <div className={style.resultModalContainer_title}>
                        {t("The prediction will be created after moderation")}
                    </div>
                    <div className={style.resultModalContainer_buttons_button} onClick={() => navigate("/predict/predictions")}>
                        Ok
                    </div>
                </Card>
            </Modal>
        </div>
    )
}
