import React, { PropsWithChildren, useContext, useEffect, useState } from 'react'
import style from './layoutStyle.module.scss'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import GameContext from '../../../Context/GameContext';
import WebAppUser from "@twa-dev/sdk";
import { UserProvider } from '../../../Domain/User/userProvider';
import PLoader from '../loader/Loader';
import BottomMenu from '../../BottomMenu';
import WebApp from '@twa-dev/sdk';
import { useTheme } from '../../../Context/Predict/themeContext';

export function PredictLayout() {
    const { updateGame, userId, coinsPerSecond } = useContext(GameContext);
    const [loaded, setLoaded] = useState<boolean>(false);

    const navigate = useNavigate();
    const location = useLocation();

    const { setClickerTheme } = useTheme();

    useEffect(() => {
        if (!userId) return;

        authUser();

        if (location.pathname === '/') navigate(`/${userId}`);
    }, []);

    async function authUser() {
        let user_language = (WebAppUser.initDataUnsafe && WebAppUser.initDataUnsafe.user) ? WebAppUser.initDataUnsafe.user.language_code : "ru";

        const response = await UserProvider.authUser(userId, user_language!, WebAppUser.initDataUnsafe?.start_param,
            WebAppUser.initDataUnsafe.user?.first_name,
            WebAppUser.initDataUnsafe.user?.last_name,
            WebAppUser.initDataUnsafe.user?.username,
            WebAppUser.initDataUnsafe.user?.language_code
        )

        let user_score = parseFloat(response.user.balance);
        let temp_coins_per_second = parseFloat(response.user.coins_per_second);
        const dao_balance = parseFloat(response.user.dao_balance);

        updateGame({
            score: user_score,
            coinsPerSecond: temp_coins_per_second,
            family_id: response.user.family_id ? parseInt(response.user.family_id) : null,
            token: response.token,
            dao_balance: dao_balance
        });

        if (parseInt(response.user.coin_image_id) === 9) {
            // document.body.classList.add('golden-pixie-coin-event');
        }

        setLoaded(true);
    }

    useEffect(() => {
        if (location.pathname === '/predict' || location.pathname === '/predict/') navigate(`/predict/predictions`);
    }, [])

    useEffect(() => {
        const interval = setInterval(() => {
            updateGame(prev => ({
                ...prev,
                score: prev.score + prev.coinsPerSecond,
                energy: prev.energy >= prev.maxEnergy ? prev.energy = prev.maxEnergy : prev.energy + prev.energyPerSecond,
                totalEarn: prev.totalEarn + prev.coinsPerSecond,
                eventTimeEndSecond: prev.eventTimeEndSecond - 1
            }));
        }, 1000);

        return () => clearInterval(interval);
    }, [coinsPerSecond]);

    if (!loaded) return <PLoader />

    return (
        <div className={style.app}>
            <Outlet />
            <div className={style.bottomMenu}>
                <BottomMenu />
            </div>
        </div>
    )
}
