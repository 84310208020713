import style from './profileStyle.module.scss'
import PixieLogo from '../../../Resources/Predict/images/PixieLogo.png'
import BackIcon from '../../../Resources/Predict/icons/backSmall.svg'
import smallInfoIcon from '../../../Resources/Predict/icons/smallinfo.svg'
import { useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'

enum Tab {
    Overview = 1,
    Statistics = 2,
    NFTsales = 3
}

export function ProfileNftBlock() {
    const [selectedTab, setSelectedTab] = useState<Tab>(Tab.Overview);
    const navigate = useNavigate();

    const { id } = useParams();

    useEffect(() => {
        console.log(id)
    }, [id])

    return (
        <div className={style.profileNftBlock}>
            <div className={style.profileNftBlock_header}>
                <div className={style.profileNftBlock_title}>
                    <div className={style.profileNftBlock_header_back} onClick={() => navigate(-1)}>
                        <img src={BackIcon} alt="BackIcon" /></div>
                    NFTs Details
                </div>
            </div>
            <div className={style.profileNftBlock_content}>
                <div className={style.profileNftBlock_content_image}>
                    <img src={PixieLogo} alt="fullNft" />
                </div>
                <div className={style.profileNftBlock_content_title}>
                    Abstract
                </div>

                <div className={style.profileNftBlock_content_subtitle}>
                    Bored Ape Yacht Club is an exclusive NFT collection that provides owners with unique avatars and access to a closed community with privileges, events and bonuses. Join the elite of digital art!
                </div>

                <div className={style.profileNftBlock_content_createdBlock}>
                    <div className={style.profileNftBlock_content_createdBlock_left}>
                        <img src={PixieLogo} />
                    </div>
                    <div className={style.profileNftBlock_content_createdBlock_right}>
                        <span>Created by</span>
                        <span>System</span>
                    </div>
                </div>

                <div className={style.navigation}>
                    <div className={`${style.navigation_item} ${selectedTab == Tab.Overview ? style.active : ""}`} onClick={() => setSelectedTab(Tab.Overview)}>
                        Overview
                    </div>
                    <div className={`${style.navigation_item} ${selectedTab == Tab.Statistics ? style.active : ""}`} onClick={() => setSelectedTab(Tab.Statistics)}>
                        Statistics
                    </div>
                    <div className={`${style.navigation_item} ${selectedTab == Tab.NFTsales ? style.active : ""}`} onClick={() => setSelectedTab(Tab.NFTsales)}>
                        NFT sales
                    </div>
                </div>

                {
                    selectedTab == Tab.NFTsales &&
                    <div className={style.tabContent}>
                        <div className={style.tabContent_item}>
                            <div className={style.tabContent_item_header}>
                                <span>Price</span>
                                <img src={smallInfoIcon} alt="smallInfoIcon" />
                            </div>
                            <div className={style.tabContent_item_content}>
                                <span>$1.48M</span>
                                <span>+62.07%</span>
                            </div>
                        </div>
                        <div className={style.tabContent_item}>
                            <div className={style.tabContent_item_header}>
                                <span>Rarity</span>
                                <img src={smallInfoIcon} alt="smallInfoIcon" />
                            </div>
                            <div className={style.tabContent_item_content}>
                                <span>$1.48M</span>
                                <span>+62.07%</span>
                            </div>
                        </div>
                        <div className={style.tabContent_item}>
                            <div className={style.tabContent_item_header}>
                                <span>PIX earned</span>
                                <img src={smallInfoIcon} alt="smallInfoIcon" />
                            </div>
                            <div className={style.tabContent_item_content}>
                                <span>$1.48M</span>
                                <span>+62.07%</span>
                            </div>
                        </div>
                        <div className={style.tabContent_item}>
                            <div className={style.tabContent_item_header}>
                                <span>USDT earned</span>
                                <img src={smallInfoIcon} alt="smallInfoIcon" />
                            </div>
                            <div className={style.tabContent_item_content}>
                                <span>$1.48M</span>
                                <span>+62.07%</span>
                            </div>
                        </div>
                    </div>
                }

                {
                    selectedTab == Tab.Overview &&
                    <div className={style.tabContent}>
                        <div className={style.tabContent_item}>
                            <div className={style.tabContent_item_header}>
                                <span>Price</span>
                                <img src={smallInfoIcon} alt="smallInfoIcon" />
                            </div>
                            <div className={style.tabContent_item_content}>
                                <span>$1.48M</span>
                                <span>+62.07%</span>
                            </div>
                        </div>
                        <div className={style.tabContent_item}>
                            <div className={style.tabContent_item_header}>
                                <span>Rarity</span>
                                <img src={smallInfoIcon} alt="smallInfoIcon" />
                            </div>
                            <div className={style.tabContent_item_content}>
                                <span>$1.48M</span>
                                <span>+62.07%</span>
                            </div>
                        </div>
                        <div className={style.tabContent_item}>
                            <div className={style.tabContent_item_header}>
                                <span>PIX earned</span>
                                <img src={smallInfoIcon} alt="smallInfoIcon" />
                            </div>
                            <div className={style.tabContent_item_content}>
                                <span>$1.48M</span>
                                <span>+62.07%</span>
                            </div>
                        </div>
                        <div className={style.tabContent_item}>
                            <div className={style.tabContent_item_header}>
                                <span>USDT earned</span>
                                <img src={smallInfoIcon} alt="smallInfoIcon" />
                            </div>
                            <div className={style.tabContent_item_content}>
                                <span>$1.48M</span>
                                <span>+62.07%</span>
                            </div>
                        </div>
                    </div>
                }

                {
                    selectedTab == Tab.Statistics &&
                    <div className={style.tabContent}>
                        <div className={style.tabContent_item}>
                            <div className={style.tabContent_item_header}>
                                <span>Price</span>
                                <img src={smallInfoIcon} alt="smallInfoIcon" />
                            </div>
                            <div className={style.tabContent_item_content}>
                                <span>$1.48M</span>
                                <span>+62.07%</span>
                            </div>
                        </div>
                        <div className={style.tabContent_item}>
                            <div className={style.tabContent_item_header}>
                                <span>Rarity</span>
                                <img src={smallInfoIcon} alt="smallInfoIcon" />
                            </div>
                            <div className={style.tabContent_item_content}>
                                <span>$1.48M</span>
                                <span>+62.07%</span>
                            </div>
                        </div>
                        <div className={style.tabContent_item}>
                            <div className={style.tabContent_item_header}>
                                <span>PIX earned</span>
                                <img src={smallInfoIcon} alt="smallInfoIcon" />
                            </div>
                            <div className={style.tabContent_item_content}>
                                <span>$1.48M</span>
                                <span>+62.07%</span>
                            </div>
                        </div>
                        <div className={style.tabContent_item}>
                            <div className={style.tabContent_item_header}>
                                <span>USDT earned</span>
                                <img src={smallInfoIcon} alt="smallInfoIcon" />
                            </div>
                            <div className={style.tabContent_item_content}>
                                <span>$1.48M</span>
                                <span>+62.07%</span>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}
