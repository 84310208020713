import React from 'react'
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Card } from '../../../Components/Predict/card/card';
import DrawIcon from '../../../Resources/Predict/icons/draw2.svg';
import CalendarIcon from '../../../Resources/Predict/icons/calendar.svg';
import BackIcon from '../../../Resources/Predict/icons/back.svg';
import CheckedIcon from '../../../Resources/Predict/icons/Checked.svg';
import CrossIcon from '../../../Resources/Predict/icons/cross.svg';
import CoinIcon from '../../../Resources/Predict/icons/coin.svg';
import TopRightArrow_light from '../../../Resources/Predict/icons/topRightArrow_light.svg';
import SelectedTopRightArrow_light from '../../../Resources/Predict/icons/selected_topRightArrow_light.svg';
import DownRightArrow_light from '../../../Resources/Predict/icons/downRightArrow_light.svg';
import SelectedDownRightArrow_light from '../../../Resources/Predict/icons/selected_downRightArrow_light.svg';
import style from './profileStyle.module.scss';
import { DateTime } from 'luxon';
import { UserProvider } from '../../../Domain/User/userProvider';
import GameContext from '../../../Context/GameContext';
import { UserGameHistory } from '../../../Domain/User/userGameHistory';
import PLoader from '../../../Components/Predict/loader/Loader';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Glide from '@glidejs/glide';
import { ActiveGame } from '../../../Domain/Predict/acitveGame';
import { DownButton } from '../../../Components/Predict/actionButton/downButton';
import { UpButton } from '../../../Components/Predict/actionButton/upButton';
import './sliderStyle.css'
import { initSwipeBehavior } from '@telegram-apps/sdk-react';
import { GameProcess } from '../../../Domain/Predict/gameProcess';
import { PredictionResultModal } from '../predictions/fast/resultModal/resultModal';
import { ProfileGameHistoryBlock } from './gameHistory';
import { ProfileBalanceHistoryBlock } from './balanceHistory';

enum pageType {
    games = 1,
    balance = 2
}

export default function ProfileHistoryBlock() {
    const { token, score, userId } = useContext(GameContext);
    const navigate = useNavigate();

    const [activeGames, setActiveGames] = useState<ActiveGame[]>([]);
    const [currentDateTimeUtc, setCurrentDateTimeUtc] = useState<Date | null>(null);
    const [selectedPageType, setSelectedPageType] = useState<pageType>(pageType.games);

    const { t } = useTranslation();
    const [swipeBehavior] = initSwipeBehavior();

    useEffect(() => {
        if (swipeBehavior.supports("disableVerticalSwipe")) swipeBehavior.disableVerticalSwipe();

        return () => {
            if (swipeBehavior.supports("enableVerticalSwipe")) swipeBehavior.enableVerticalSwipe();
        }
    }, [])

    return (
        <div className={style.profileHistoryBlock}>
            <div className={style.profileHistoryBlock_header}>
                <div className={style.profileHistoryBlock_title}>
                    <div className={style.profileHistoryBlock_header_back} onClick={() => navigate(-1)}>
                        <img src={BackIcon} alt="BackIcon" /></div>
                    {t('History')}
                </div>
            </div>

            <div className={style.profileHistoryBlock_content}>
                <div className={style.profileHistoryBlock_navigation}>
                    <div className={`${style.profileHistoryBlock_navigation_item} ${selectedPageType == pageType.games ? style.active : ""}`} onClick={() => setSelectedPageType(pageType.games)}>
                        Games
                    </div>
                    <div className={`${style.profileHistoryBlock_navigation_item} ${selectedPageType == pageType.balance ? style.active : ""}`} onClick={() => setSelectedPageType(pageType.balance)}>
                        Balance
                    </div>
                </div>

                {
                    selectedPageType == pageType.games
                        ? <ProfileGameHistoryBlock />
                        : <ProfileBalanceHistoryBlock />
                }

            </div>
        </div>
    )
}
