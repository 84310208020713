import React, { useEffect, useState } from 'react'
import style from './profileStyle.module.scss'
import nft1 from '../../../Resources/Predict/images/nfs/1.png'
import searchNftIcon from '../../../Resources/Predict/icons/searchnft.svg'
import filterIcon from '../../../Resources/Predict/icons/filterIcon.svg'
import smallArrowDown from '../../../Resources/Predict/icons/smallarrordown.svg'
import nft2 from '../../../Resources/Predict/images/nfs/2.png'
import nft3 from '../../../Resources/Predict/images/nfs/3.png'
import nft4 from '../../../Resources/Predict/images/nfs/4.png'
import { useNavigate } from 'react-router-dom'

interface TableRow {
    id: number;
    type: string;
    price: string;
    pricePercent: string;
    usdtEarned: string;
    usdtEarnedPercent: string;
    pixEarned: string;
    pixEarnedPercent: string;
    image: string;
}

export interface nft {
    name: string,
    number: string,
    price: number,
    code: number,
    photo: string
}

const data: TableRow[] = [
    { id: 1, type: 'Ntf 1', price: '$14.45 k', pricePercent: "+127.7%", usdtEarned: '$14.45 k', pixEarned: '$14.45 k', image: nft1, usdtEarnedPercent: "+62.07%", pixEarnedPercent: "+27.07%" },
    { id: 2, type: 'Ntf 2', price: '$14.45 k', pricePercent: "+127.7%", usdtEarned: '$14.45 k', pixEarned: '$14.45 k', image: nft2, usdtEarnedPercent: "+62.07%", pixEarnedPercent: "+27.07%" },
    { id: 3, type: 'Ntf 3', price: '$14.45 k', pricePercent: "+127.7%", usdtEarned: '$14.45 k', pixEarned: '$14.45 k', image: nft3, usdtEarnedPercent: "+62.07%", pixEarnedPercent: "+27.07%" },
    { id: 4, type: 'Ntf 4', price: '$14.45 k', pricePercent: "+127.7%", usdtEarned: '$14.45 k', pixEarned: '$14.45 k', image: nft4, usdtEarnedPercent: "+62.07%", pixEarnedPercent: "+27.07%" },
];

export function ProfileNftsBlock() {
    const [profileNftFilterModalIsOpen, setProfileNftFilterModalIsOpen] = useState<boolean>(false);

    const navigate = useNavigate();

    function closeProfileNftFilterModal() {
        setProfileNftFilterModalIsOpen(prev => !prev);
    }

    return (
        <div className={style.profileNftsBlock}>
            {/* <div className={style.profileNftsBlock_searchInput}>
                <img src={searchNftIcon} alt="SearchIcon" />
                <input type='text' placeholder='Search' />
                <img src={filterIcon} alt="filterIcon" onClick={closeProfileNftFilterModal} />
            </div> */}
            <div className={style.tableContainer}>
                <table>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Type</th>
                            <th>Price</th>
                            <th>USDT earned</th>
                            <th>PIX earned</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map(row => (
                            <tr key={row.id}>
                                <td>
                                    <span>{row.id}</span>
                                </td>
                                <td>
                                    <div className={style.table_tbRow} onClick={() => navigate(`/predict/profile/my-nfts/${row.id}`)}>
                                        <img src={row.image} alt={`row-${row.id}`} className={style.tableImage} />
                                        <span className={style.table_title}>{row.type}</span>
                                    </div>
                                </td>
                                <td>
                                    <div className={style.table_tbColumn}>
                                        <span>{row.price}</span>
                                        <span className={style.positive}>{row.pricePercent}</span>
                                    </div>
                                </td>
                                <td >
                                    <div className={style.table_tbColumn}>
                                        <span>{row.usdtEarned}</span>
                                        <span className={style.positive}>{row.usdtEarnedPercent}</span>
                                    </div>
                                </td>
                                <td >
                                    <div className={style.table_tbColumn}>
                                        <span>{row.pixEarned}</span>
                                        <span className={style.positive}>{row.pixEarnedPercent}</span>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {
                profileNftFilterModalIsOpen &&
                <ProfileNftFilterModal onClose={() => setProfileNftFilterModalIsOpen(false)} />
            }
        </div>
    )
}

interface IProfileNftFilterModalProps {
    onClose: () => void;
}

function ProfileNftFilterModal(props: IProfileNftFilterModalProps) {
    return (
        <div className={style.profileNftsBlock_filterModal} onClick={() => props.onClose()}>
            <div className={style.profileNftsBlock_filterModal_content} onClick={e => e.stopPropagation()}>
                <div className={style.profileNftsBlock_filterModal_content_header}>
                    <div className={style.profileNftsBlock_filterModal_content_header_closeButton} onClick={props.onClose}>✕</div>
                </div>
                <div className={style.profileNftsBlock_filterModal_content_block}>
                    <div className={style.profileNftsBlock_filterModal_content_title}>
                        Floor Price ($)
                    </div>
                    <div className={style.profileNftsBlock_filterModal_content_grid}>
                        <div className={style.profileNftsBlock_filterModal_content_item}>
                            <input type='text' placeholder='$0' />
                        </div>
                        <div className={style.profileNftsBlock_filterModal_content_grid_divider}>
                            to
                        </div>
                        <div className={style.profileNftsBlock_filterModal_content_item}>
                            <input type='text' placeholder='$999,000' />
                        </div>
                    </div>

                    <div className={style.profileNftsBlock_filterModal_content_subtitle}>
                        Popular ranges
                    </div>

                    <div className={style.profileNftsBlock_filterModal_content_popularRanges}>
                        <div className={style.profileNftsBlock_filterModal_content_popularRanges_item}>
                            51k
                        </div>
                        <div className={style.profileNftsBlock_filterModal_content_popularRanges_item}>
                            57k
                        </div>
                        <div className={style.profileNftsBlock_filterModal_content_popularRanges_item}>
                            63k
                        </div>
                        <div className={style.profileNftsBlock_filterModal_content_popularRanges_item}>
                            100k
                        </div>
                    </div>
                </div>

                <div className={style.profileNftsBlock_filterModal_content_divider} />

                <div className={style.profileNftsBlock_filterModal_content_block}>
                    <div className={style.profileNftsBlock_filterModal_content_title}>
                        Type
                        <img src={smallArrowDown} alt="smallArrowDown" />
                    </div>
                    <div className={style.profileNftsBlock_filterModal_content_grid}>
                        <div className={style.profileNftsBlock_filterModal_content_item}>
                            <input type='text' placeholder='$0' />
                        </div>
                        <div className={style.profileNftsBlock_filterModal_content_grid_divider}>
                            to
                        </div>
                        <div className={style.profileNftsBlock_filterModal_content_item}>
                            <input type='text' placeholder='$999,000' />
                        </div>
                    </div>

                    <div className={style.profileNftsBlock_filterModal_content_subtitle}>
                        Popular ranges
                    </div>

                    <div className={style.profileNftsBlock_filterModal_content_popularRanges}>
                        <div className={style.profileNftsBlock_filterModal_content_popularRanges_item}>
                            51k
                        </div>
                        <div className={style.profileNftsBlock_filterModal_content_popularRanges_item}>
                            57k
                        </div>
                        <div className={style.profileNftsBlock_filterModal_content_popularRanges_item}>
                            63k
                        </div>
                        <div className={style.profileNftsBlock_filterModal_content_popularRanges_item}>
                            100k
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
