import { DateTime } from 'luxon'
import { ChangeEvent, useContext, useEffect, useMemo, useState } from 'react'
import toast, { Toaster } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { Accordion } from '../../../../Components/Predict/accordion/accordion'
import { Card } from '../../../../Components/Predict/card/card'
import PLoader from '../../../../Components/Predict/loader/Loader'
import GameContext from '../../../../Context/GameContext'
import { useTheme } from '../../../../Context/Predict/themeContext'
import { CustomGameProcess } from '../../../../Domain/Predict/customGameProcess'
import { Game } from '../../../../Domain/Predict/game'
import { PredictProvider } from '../../../../Domain/Predict/predictProdiver'
import CalendarIcon from '../../../../Resources/Predict/icons/calendar.svg'
import CoinIcon from '../../../../Resources/Predict/icons/coin.svg'
import LinkIcon from '../../../../Resources/Predict/icons/link.svg'
import LinkDarkIcon from '../../../../Resources/Predict/icons/link_dark.svg'
import LockDarkIcon from '../../../../Resources/Predict/icons/lock_dark.svg'
import LockLightIcon from '../../../../Resources/Predict/icons/lock_light.svg'
import MinusIcon from '../../../../Resources/Predict/icons/minus.svg'
import MinusDarkIcon from '../../../../Resources/Predict/icons/minus_dark.svg'
import PlusIcon from '../../../../Resources/Predict/icons/plus.svg'
import PlusDarkIcon from '../../../../Resources/Predict/icons/plus_dark.svg'
import PixieLogo from '../../../../Resources/Predict/images/PixieLogo.png'
import СoinIcon from '../../../../Resources/Predict/images/coin.svg'
import СoinBrownIcon from '../../../../Resources/Predict/icons/coin_brown.svg'
import { BetType } from '../fast/setPositionModal/setPositionModal'
import style from './someVariantsStyle.module.scss'

interface IVariant {
  title: string;
  image: string | null;
}

interface IProps {
  trueVariant: IVariant;
  falseVariant: IVariant;
}

export function SomePredictionVariantsPage(props: IProps) {
  const { token, score, updateGame, userId, dao_balance } = useContext(GameContext);
  const predictBalance = useMemo(() => Math.round(score / 1_000_000), [score])

  const [game, setGame] = useState<Game | null>(null);
  const [gameProcess, setGameProcess] = useState<CustomGameProcess | null>(null);
  const [selectedVariant, setSelectedVariant] = useState<BetType | null>(null);
  const [dateTimeUtcNow, setDateTimeUtcNow] = useState<Date>(new Date());

  const [betPrice, setBetPrice] = useState<number | null>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  let { id } = useParams();
  const { theme } = useTheme();

  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    loadGameInfo();
  }, [])

  useEffect(() => {
    if (game?.id == null) return;

    const updateInterval = setInterval(() => { loadGameProcess(game.id) }, 10_000)

    return () => {
      clearInterval(updateInterval);
    }

  }, [game?.id])

  async function loadGameInfo() {
    if (id == null) return;

    setIsLoading(true);
    const { game, datetime_now } = await PredictProvider.getGame(+id, token!);
    setGame(game);
    setDateTimeUtcNow(datetime_now);
    console.log(datetime_now);

    await loadGameProcess(game.id);

    setIsLoading(false);
  }

  async function loadGameProcess(gameId: number) {
    const customGameProcess = await PredictProvider.getCustomGameProcess(gameId, token!);
    setGameProcess(customGameProcess);




    if (customGameProcess.user_in_game && customGameProcess.user_type_bet != null) {
      setSelectedVariant(customGameProcess.user_type_bet ? BetType.Up : BetType.Down);
    }
  }

  const fundAllocationPie = [
    { persent: 2.9, text: t("Commission to the licence holders") },
    { persent: 6, text: t("Commission to the NFT house owners") },
    { persent: 6, text: t("Commission to the NFT landlords") },
    { persent: 5, text: t("Staking fund") },
    { persent: 1, text: t("Charity fund") },
    { persent: 1, text: t("Commission of the game creator") },
    { persent: 1, text: t("Lottery pool") },
    { persent: 1, text: t("Referral bonus") },
    { persent: 0.7, text: t("Pool for Pixie Family Contests") },
    { persent: 0.1, text: t("Burn") },
    { persent: 0.2, text: t("to 5 metaverse street Diamond houses owners") },
    { persent: 0.1, text: t("Project fee") },
  ]


  function handleBetPriceChange(event: ChangeEvent<HTMLInputElement>) {
    const value = event.target.value.replace(",", "");
    const numberValue = value == "" ? 0 : parseInt(value);
    if (isNaN(numberValue)) return;

    setBetPrice(numberValue);
  }

  function minusAmountEvent() {
    if (gameProcess == null) return;
    if (gameProcess.user_in_game) return;
    if (predictionInActive()) return;

    if ((betPrice ?? 0) - 1 < 0) return;

    setBetPrice(prev => (prev ?? 0) - 1)
  }

  function plusAmountEvent() {
    if (gameProcess == null) return;
    if (gameProcess.user_in_game) return;
    if (predictionInActive()) return;

    setBetPrice(prev => (prev ?? 0) + 1)
  }

  async function confirmPrediction() {
    if (id == null) return;
    if (gameProcess == null) return;
    if (selectedVariant == null) return;
    if (gameProcess.user_in_game) return;
    if (betPrice == null) return;
    if (predictBalance < betPrice) return;
    if (betPrice == 0 || betPrice < 0) return;
    if (predictionInActive()) return;

    setIsLoading(true);
    const response = await PredictProvider.bet(selectedVariant, gameProcess.game_process_id, betPrice, token!);
    if (response.message == 'ok') {
      updateGame({
        score: score - betPrice * 1_000_000,
        dao_balance: dao_balance + (+response.dao_bonus_for_bet)
      })
      toast.success("Success");
      setBetPrice(0);
    }
    else {
      toast.error("Error :c");
    }

    await loadGameProcess(game!.id);
    setIsLoading(false);
  }

  function selectVariant(betType: BetType) {
    if (gameProcess?.user_in_game == true) return;

    setSelectedVariant(betType)
  }

  const predictionInActive = () => game?.end_at != null && dateTimeUtcNow > game.end_at;

  if (isLoading) return <PLoader />

  return (
    <div className={style.somePredictionContainer}>
      <div className={style.header}>
        <div className={style.header_logo} onClick={() => navigate("/predict/predictions")}>
          <img src={PixieLogo} alt="PixieLogo" />
        </div>
        <div className={style.header_rightBlock}>
          <div className={style.header_rightBlock_element} onClick={() => navigate("/predict/profile/history")}>
            <div>
              <img src={СoinIcon} alt="СoinIcon" />
              <div className={style.header_rightBlock_element_title}>
                {predictBalance.toLocaleString("en")}
              </div>
            </div>
            <div>
              <img src={СoinBrownIcon} alt="СoinIcon" />
              <span className={style.header_rightBlock_element_title}>{(+dao_balance.toFixed(2)).toLocaleString("en")}</span>
            </div>
          </div>
        </div>
      </div>

      {
        game != null && gameProcess != null &&
        <div className={style.someVariants_conatiner}>
          <Card className={style.somePredictionContainer_card}>
            <div className={style.somePredictionContainer_card_container}>
              <div className={style.somePredictionContainer_card_container_image}>
                <img src={game.image ? `${process.env.REACT_APP_FILE_STORAGE_URL}/${game.image}` : ""} alt="PredictionImage" /></div>
              <div className={style.somePredictionContainer_card_container_right}>
                <div className={style.somePredictionContainer_card_container_right_header}>
                  <div className={style.somePredictionContainer_card_container_right_header_title}>
                    <span>
                      Created by {game.creator_telegram_id == -1
                        ? <span >System</span>
                        : (game.last_name != null || game.first_name != null)
                          ? <span >
                            {game.first_name != null && `${game.first_name} `}

                            {game.last_name != null && game.last_name}
                          </span>
                          : <span>System</span>
                      }
                    </span>
                  </div>
                  <div className={style.somePredictionContainer_card_container_right_header_buttons}>
                    <a href={`https://t.me/share/url?url=https://t.me/pixie_project_bot?start=predict${game.id}_${userId}&text=${game.description}`}>
                      {
                        theme == "dark"
                          ? <img src={LinkDarkIcon} alt="LinkIcon" />
                          : <img src={LinkIcon} alt="LinkIcon" />
                      }
                    </a>
                    {/* {
                                theme == "dark"
                                    ? <img src={OutlineStarDarkIcon} alt="OutlineStarIcon" />
                                    : <img src={OutlineStarIcon} alt="OutlineStarIcon" />
                            } */}
                  </div>
                </div>
                <div className={style.somePredictionContainer_card_container_right_title}>
                  {game.name}
                </div>
              </div>
            </div>
            <div className={style.somePredictionContainer_card_container_downInfo}>
              <div>
                <img src={CoinIcon} alt="PixieDarkIcon" />
                <span>{(+((+(gameProcess.prize_pool ?? 0)).toFixed(2))).toLocaleString("en")} {t("Bet")}</span>
              </div>
              <div>
                <img src={CalendarIcon} alt="CalendarIcon" />
                {game.start_at != null && <span>{DateTime.fromJSDate(game.start_at).toFormat('MMM d, yyyy')}</span>}
              </div>
            </div>
          </Card>

          <Card className={style.somePredictionContainer_variantBloack}>

            <div className={style.somePredictionContainer_variantBloack_item}>
              <div className={style.somePredictionContainer_variantBloack_info}>
                <div className={style.somePredictionContainer_variantBloack_info_block}>
                  {props.trueVariant.image != null && <img src={props.trueVariant.image} alt="" />}
                  <div className={style.somePredictionContainer_variantBloack_info_title}>
                    <div>{props.trueVariant.title}</div>
                    <span>{(+(gameProcess.prize_pool_up ?? 0).toFixed(2)).toLocaleString("en")} {t("Bet")}</span>
                  </div>
                </div>
                <div className={style.somePredictionContainer_variantBloack_rightBlock}>
                  {(gameProcess.percents_up ?? 0).toFixed(1)}%
                </div>
              </div>
              <div className={style.somePredictionContainer_variantBloack_buttons}>
                <div className={`${style.somePredictionContainer_variantBloack_buttons_button} ${selectedVariant == BetType.Up ? style.selected : ""} ${(gameProcess.user_in_game || predictionInActive()) ? style.disabled : ""}`} onClick={() => selectVariant(BetType.Up)}>
                  <span className={style.somePredictionContainer_variantBloack_buttons_button_title}>
                    {game.button_name_up}
                  </span>
                  {gameProcess.coef_up != null && <span className={`${style.somePredictionContainer_variantBloack_buttons_button_subTitle}`}>{`${(+gameProcess.coef_up).toFixed(2)}x`}</span>}
                </div>
              </div>
            </div>

            <div className={style.somePredictionContainer_variantBloack_item}>
              <div className={style.somePredictionContainer_variantBloack_info}>
                <div className={style.somePredictionContainer_variantBloack_info_block}>
                  {props.falseVariant.image != null && <img src={props.falseVariant.image} alt="" />}
                  <div className={style.somePredictionContainer_variantBloack_info_title}>
                    <div>{props.falseVariant.title}</div>
                    <span>{(+(gameProcess.prize_pool_down ?? 0).toFixed(2)).toLocaleString("en")} {t("Bet")}</span>
                  </div>
                </div>
                <div className={style.somePredictionContainer_variantBloack_rightBlock}>
                  {(gameProcess.percents_down ?? 0).toFixed(1)}%
                </div>
              </div>
              <div className={style.somePredictionContainer_variantBloack_buttons}>
                <div className={`${style.somePredictionContainer_variantBloack_buttons_button} ${selectedVariant == BetType.Down ? style.selected : ""} ${(gameProcess.user_in_game || predictionInActive()) ? style.disabled : ""}`} onClick={() => selectVariant(BetType.Down)}>
                  <span className={style.somePredictionContainer_variantBloack_buttons_button_title}>
                    {game.button_name_down}
                  </span>
                  {gameProcess.coef_down != null && <span className={`${style.somePredictionContainer_variantBloack_buttons_button_subTitle}`}>{`${(+gameProcess.coef_down).toFixed(2)}x`}</span>}
                </div>
              </div>
            </div>

            <div className={style.somePredictionContainer_variantBloack_moneyBlock} style={{ opacity: (gameProcess.user_in_game || predictionInActive()) ? 0.6 : 1 }}>
              <img src={theme == 'dark' ? MinusDarkIcon : MinusIcon} alt="MinusIcon" onClick={minusAmountEvent} />
              <input
                type="text"
                pattern="[0-9]+([,][0-9]{1,2})?"
                disabled={gameProcess.user_in_game || predictionInActive()}
                value={betPrice?.toLocaleString("en")}
                onChange={handleBetPriceChange}
              />
              <img src={theme == 'dark' ? PlusDarkIcon : PlusIcon} alt="PlusIcon" onClick={plusAmountEvent} />
            </div>

            <div className={`${style.somePredictionContainer_variantBloack_buttons_confirmButton} ${(gameProcess?.user_in_game || betPrice == 0 || predictionInActive()) ? style.disabled : ""}`} onClick={confirmPrediction}>
              {predictionInActive()
                ? <div>
                  <img src={theme == 'dark' ? LockDarkIcon : LockLightIcon} alt="LockIcon" />
                  <div>{t("Bets are no longer accepted")}</div>
                </div>
                : t("Confirm prediction")
              }
            </div>
          </Card>

          <div className={style.somePredictionContainer_detailedInformationBlock}>
            {
              game.description &&
              <Accordion title={t('Description and the condition')}>
                <div className={style.somePredictionContainer_detailedInformationBlock_description}>
                  {game.description}
                </div>
              </Accordion>
            }

            {/* <Accordion title='Graph'>
                    <div className={style.somePredictionContainer_detailedInformationBlock_graph}>
                        <span>23 % chance</span>
                        <ReactApexChart options={options} series={series} type="line" height="100%" />
                    </div>
                    <CommentBlock comments={comments} onSendComment={() => { }} addCommentInputColor='dark' />
                </Accordion> */}
            <Accordion title={t('Fund Allocation Pie')}>
              <div className={style.somePredictionContainer_detailedInformationBlock_FundAllocationPie}>
                {
                  fundAllocationPie.map((f, i) =>
                    <div key={i} className={style.somePredictionContainer_detailedInformationBlock_FundAllocationPie_item}>
                      <div className={style.somePredictionContainer_detailedInformationBlock_FundAllocationPie_item_left}>
                        {f.persent}%
                      </div>
                      <div className={style.somePredictionContainer_detailedInformationBlock_FundAllocationPie_item_right}>
                        {f.text}
                      </div>
                    </div>
                  )
                }
              </div>
            </Accordion>
          </div>
          <div><Toaster /></div>
        </div>
      }
    </div>
  )
}
